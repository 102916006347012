const { _ } = window;

export default class Toggle {
  constructor() {
    this.selectors = {
      toggle: '.js-toggle',
    };

    this.classes = {
      open: 'is-open',
    };
  }

  init() {
    this.getDOM();
    this.bindClickEvent();
    this.bindWindowResizeEvent();
  }

  getDOM() {
    this.DOM = {
      toggles: document.querySelectorAll(this.selectors.toggle),
    };
  }

  bindClickEvent() {
    this.DOM.toggles.forEach(toggle => {
      toggle.addEventListener('click', event => {
        event.preventDefault();

        if (this.isToggleExpanded(toggle)) {
          this.closeToggle(toggle);
        } else {
          this.openToggle(toggle);
        }
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  isToggleExpanded(toggle) {
    return toggle.getAttribute('aria-expanded') === 'true';
  }

  setToggleContentAttributes(toggleContent, state) {
    if (state === 'open') {
      // eslint-disable-next-line no-param-reassign
      toggleContent.style.maxHeight = `${toggleContent.scrollHeight}px`;
      toggleContent.setAttribute('aria-hidden', 'false');
    } else if (state === 'close') {
      // eslint-disable-next-line no-param-reassign
      toggleContent.style.maxHeight = `0px`;
      toggleContent.classList.remove(this.classes.open);
      toggleContent.setAttribute('aria-hidden', 'true');
    }
  }

  setToggleButtonAttributes(toggle, state) {
    if (state === 'open') {
      toggle.setAttribute('aria-expanded', 'true');
      toggle.classList.add(this.classes.open);
    } else if (state === 'close') {
      toggle.setAttribute('aria-expanded', 'false');
      toggle.classList.remove(this.classes.open);
    }
  }

  openToggle(toggle) {
    const toggleContent = document.querySelector(
      `#${toggle.getAttribute('aria-controls')}`,
    );

    toggle.classList.add(this.classes.open);
    toggleContent.classList.add(this.classes.open);

    this.setToggleButtonAttributes(toggle, 'open');
    this.setToggleContentAttributes(toggleContent, 'open');
  }

  closeToggle(toggle) {
    const toggleContent = document.querySelector(
      `#${toggle.getAttribute('aria-controls')}`,
    );

    toggle.classList.remove(this.classes.open);
    toggleContent.classList.remove(this.classes.open);
    this.setToggleButtonAttributes(toggle, 'close');
    this.setToggleContentAttributes(toggleContent, 'close');
  }

  getToggleContentIds() {
    const ids = [];
    this.DOM.toggles.forEach(toggle => {
      const content = document.querySelector(
        `#${toggle.getAttribute('aria-controls')}`,
      );

      if (content.classList.contains(this.classes.open)) {
        ids.push(content.id);
      }
    });

    return ids;
  }

  bindWindowResizeEvent() {
    window.addEventListener(
      'resize',
      _.throttle(() => {
        this.getToggleContentIds().forEach(id => {
          const toggleContent = document.querySelector(`#${id}`);
          this.setToggleContentAttributes(toggleContent, 'open');
        });
      }, 300),
    );
  }
}
