/* eslint-disable prefer-destructuring */
export default class MagazineTilesCarousel {
  constructor() {
    this.selectors = {
      carousel: '.js-magazine-carousel',
      items: '.js-magazine-carousel-items',
      slide: '.js-magazine-carousel-item',
      arrow: '.js-magazine-carousel-arrow',
    };

    this.carousels = document.querySelectorAll(this.selectors.carousel);
  }

  init() {
    if (!this.carousels.length) {
      return;
    }

    this.carousels.forEach(carousel => {
      this.initCarousel(carousel);
    });
  }

  initCarousel(carousel) {
    this.carousel = carousel;
    this.items = this.carousel.querySelector(this.selectors.items);
    this.slides = this.carousel.querySelectorAll(this.selectors.slide);
    this.slideTotal = this.slides.length - 1;
    this.slideCurrent = -1;
    this.arrrows = this.carousel.querySelectorAll(this.selectors.arrow);

    this.touches = {
      touchstart: -1,
      touchmove: -1,
    };

    this.allClasses = [
      'prepreactive',
      'preactive',
      'active',
      'proactive',
      'preproactive',
    ];

    if (!this.slides.length) {
      return;
    }

    this.initArrows();

    setTimeout(() => {
      this.slideRight();
      this.touchEvents();
    }, 500);
  }

  setTouchStartPosition(event) {
    const touch = event.touches[0];
    this.touches[event.type] = touch.pageX;
  }

  touchEvents() {
    this.items.addEventListener(
      'touchstart',
      this.setTouchStartPosition.bind(this),
      false,
    );
    this.items.addEventListener(
      'touchmove',
      this.setTouchStartPosition.bind(this),
      false,
    );

    this.items.addEventListener(
      'touchend',
      () => {
        if (this.touches.touchstart > this.touches.touchmove) {
          this.slideRight();
        } else {
          this.slideLeft();
        }
      },
      false,
    );
  }

  initArrows() {
    this.arrrows.forEach(arrow => {
      arrow.addEventListener('click', e => {
        const type = e.target.getAttribute('data-type');

        if (type === 'prev') {
          this.slideLeft();
        } else {
          this.slideRight();
        }
      });
    });
  }

  changeSlides() {
    let preactiveSlide;
    let proactiveSlide;
    let prepreactiveSlide;
    let preproactiveSlide;

    if (this.slideCurrent < this.slideTotal) {
      proactiveSlide = this.slides[this.slideCurrent + 1];

      if (this.slideTotal > 4) {
        if (this.slideCurrent + 2 > this.slideTotal) {
          preproactiveSlide = this.slides[0];
        } else {
          preproactiveSlide = this.slides[this.slideCurrent + 2];
        }
      }
    } else {
      proactiveSlide = this.slides[0];

      if (this.slideTotal > 4) {
        preproactiveSlide = this.slides[1];
      }
    }

    const activeSlide = this.slides[this.slideCurrent];

    if (this.slideCurrent > 0) {
      preactiveSlide = this.slides[this.slideCurrent - 1];

      if (this.slideTotal > 4) {
        if (this.slideCurrent - 2 < 0) {
          prepreactiveSlide = this.slides[this.slideTotal];
        } else {
          prepreactiveSlide = this.slides[this.slideCurrent - 2];
        }
      }
    } else {
      preactiveSlide = this.slides[this.slideTotal];

      if (this.slideTotal > 4) {
        prepreactiveSlide = this.slides[this.slideTotal - 1];
      }
    }

    this.slides.forEach(elem => {
      const thisSlide = elem;

      thisSlide.classList.remove(...this.allClasses);
    });

    prepreactiveSlide.classList.add('prepreactive');
    preactiveSlide.classList.add('preactive');
    activeSlide.classList.add('active');
    proactiveSlide.classList.add('proactive');
    preproactiveSlide.classList.add('preproactive');
  }

  slideLeft() {
    if (this.slideCurrent > 0) {
      this.slideCurrent -= 1;
    } else {
      this.slideCurrent = this.slideTotal;
    }

    this.changeSlides();
  }

  slideRight() {
    if (this.slideCurrent < this.slideTotal) {
      this.slideCurrent += 1;
    } else {
      this.slideCurrent = 0;
    }

    this.changeSlides();
  }

  goToIndexSlide(index) {
    const sliding =
      this.slideCurrent > index
        ? () => this.slideRight()
        : () => this.slideLeft();
    while (this.slideCurrent !== index) {
      sliding();
    }
  }
}
