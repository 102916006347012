import A11yDialog from 'a11y-dialog';

const $ = window.jQuery;

export default class ProductDetails {
  constructor() {
    this.selectors = {
      row: '.js-recommendations-table-row',
      details: '.js-recommendations-table-details',
      collapse: '.js-recommendations-table-collapse',
      loader: '.js-recommendations-table-loader',
      dialog: '#join-us',
    };

    this.loaderHTML = `<div class="c-recommendations-table__loader js-recommendations-table-loader"></div>`;

    this.classes = {
      hasData: 'has-data',
      open: 'is-open',
      loading: 'is-loading',
    };
  }

  init() {
    this.bindClickEvent();
    this.bindCollapseEvent();
  }

  bindClickEvent() {
    $(document).on('click', this.selectors.row, event => {
      const productId = $(event.currentTarget).data('product-id');
      const row = $(`[data-product-id="${productId}"]`);
      if (row.hasClass(this.classes.hasData)) {
        if (row.hasClass(this.classes.open)) {
          row.removeClass(this.classes.open);
        } else {
          row.addClass(this.classes.open);
        }
      } else {
        row.append(this.loaderHTML);
        this.fetchData(productId);
      }
    });
  }

  bindCollapseEvent() {
    $(document).on('click', this.selectors.collapse, event => {
      $(event.currentTarget)
        .parents()
        .prev()
        .click();
    });
  }

  fetchData(productId) {
    $.ajax({
      url: `/api/recommendations/${productId}/`,
    })
      .done(response => {
        if (response.success) {
          this.insertData(response.data, productId);
        }
      })
      .fail(error => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  insertData(data, productId) {
    const $row = $(`[data-product-id="${productId}"]`);
    $row
      .addClass(this.classes.hasData)
      .addClass(this.classes.open)
      .after(data);

    $row.find(this.selectors.loader).remove();

    const element = document.querySelector(this.selectors.dialog);
    // eslint-disable-next-line no-new
    new A11yDialog(element);
  }
}
