import 'waypoints/lib/noframework.waypoints';
import 'waypoints/lib/shortcuts/inview';

export default class SectionInView {
  constructor() {
    this.selectors = {
      container: `[js-inview]`,
    };

    this.classes = {
      inview: 'is-revealed',
    };

    this.containers = null;
  }

  init() {
    this.containers = Array.from(
      document.querySelectorAll(this.selectors.container),
    );

    if (!this.containers.length) {
      return;
    }

    this.containers.forEach(container => {
      // eslint-disable-next-line no-unused-vars, no-undef
      const inview = new Waypoint.Inview({
        element: container,
        enter: direction => {
          if (direction === 'down') {
            container.classList.add(this.classes.inview);
          }
        },
        exited: direction => {
          if (direction === 'up') {
            container.classList.remove(this.classes.inview);
          }
        },
      });
    });
  }
}
