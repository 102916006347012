import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

export default class MobileMenu {
  constructor() {
    this.selectors = {
      menu: '#header-menu',
      wrapper: '.c-after-splash',
      headerWrapper: '.c-header__wrapper',
      hamburger: '.c-header__hamburger',
      searchBar: '#search-bar',
    };

    this.classes = {
      menuOpen: 'has-menu',
      active: 'is-active',
      searchOpen: 'is-search-open',
    };
  }

  init() {
    const menu = document.querySelector(this.selectors.menu);
    if (!menu) {
      return;
    }

    this.wrapper = document.querySelector(this.selectors.wrapper);

    if (!this.wrapper) {
      return;
    }

    this.searchBar = document.querySelector(this.selectors.searchBar);
    this.headerWrapper = document.querySelector(this.selectors.headerWrapper);
    this.hamburger = document.querySelector(this.selectors.hamburger);
    this.breakpoint = window.matchMedia('(min-width: 1024px)');
    this.bindResizeEvent();

    menu.addEventListener('activate:active', this.menuOpened.bind(this));
    menu.addEventListener('activate:inactive', this.menuClosed.bind(this));

    this.splashOverflow();
  }

  splashOverflow() {
    if (!this.searchBar) {
      return;
    }

    this.searchBar.addEventListener('activate:active', () => {
      this.wrapper.classList.add(this.classes.searchOpen);
    });

    this.searchBar.addEventListener('activate:inactive', () => {
      this.wrapper.classList.remove(this.classes.searchOpen);
    });
  }

  bindResizeEvent() {
    const changeEvent = event => {
      if (event.matches) {
        if (this.headerWrapper) {
          this.headerWrapper.classList.remove(this.classes.active);
        }
        if (this.hamburger) {
          this.hamburger.classList.remove(this.classes.active);
        }
        this.menuClosed();
      }
    };

    if (typeof this.breakpoint.addEventListener === 'undefined') {
      this.breakpoint.addListener(changeEvent);
    } else {
      this.breakpoint.addEventListener('change', changeEvent);
    }
  }

  menuOpened() {
    this.wrapper.classList.add(this.classes.menuOpen);
  }

  menuClosed() {
    this.wrapper.classList.remove(this.classes.menuOpen);

    window.scroll({
      top: this.wrapper.offsetTop,
      left: 0,
      behavior: 'auto',
    });
  }
}
