import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

export default class Woocommerce {
  constructor() {
    this.selectors = {
      gotoLink: '.show_and_goto_login',
      formLink: '.showlogin',
    };
  }

  init() {
    const gotoLink = document.querySelector(this.selectors.gotoLink);
    this.formLink = document.querySelector(this.selectors.formLink);

    if (!gotoLink || !this.formLink) {
      return;
    }

    gotoLink.addEventListener('click', this.goToForm.bind(this));
  }

  goToForm(ev) {
    ev.preventDefault();
    this.formLink.click();
    window.scroll({
      top: this.formLink.offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  }
}
