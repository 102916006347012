/*
  Project: Club Oenologique
  Author: Urszula Wielgosz
 */

import './polyfills/CustomEvent';
import './polyfills/Array.prototype.from';
import './polyfills/Array.prototype.findIndex';
import './polyfills/Object.prototype.assign'; // for choices
import './polyfills/Element.prototype.closest';

import './modules/throttledEvents';
import SimpleSlider from './modules/simpleSlider';
import Activate from './modules/activate';
import SectionInView from './modules/sectionInView';
import Sticky from './modules/sticky';
import ScrollTo from './modules/scrollTo';
import ToTop from './modules/toTop';
import Dropdowns from './modules/dropdowns';
import newsletterInit from './modules/newsletter';
import FormTips from './modules/formTips';
import CartCoupon from './modules/cartCoupon';
import Woocommerce from './modules/woocommerce';
import MobileMenu from './modules/mobileMenu';
import FormTip from './modules/formTip';
import MagazineTilesCarousel from './modules/magazineTilesCarousel';
import RelatedPosts from './modules/relatedPosts';
import Share from './modules/share';
import Toggle from './modules/toggle';
import ProductDetails from './modules/productDetails';

import PageScroll from './modules/pageScroll';
import 'a11y-dialog';

import AjaxFunctions from './modules/ajax';
import CoDialog from './modules/dialog';

class App {
  constructor() {
    this.activate = new Activate();
    this.simpleSlider = new SimpleSlider();
    this.sectionInView = new SectionInView();
    this.sticky = new Sticky();
    this.scrollTo = new ScrollTo();
    this.toTop = new ToTop();
    this.dropdowns = new Dropdowns();
    this.formTips = new FormTips();
    this.cartCoupon = new CartCoupon();
    this.woocommerce = new Woocommerce();
    this.mobileMenu = new MobileMenu();
    this.formTip = new FormTip();
    this.magazineTilesCarousel = new MagazineTilesCarousel();
    this.relatedPosts = new RelatedPosts();
    this.share = new Share();
    this.toggle = new Toggle();
    this.productDetails = new ProductDetails();

    this.pageScroll = new PageScroll();

    this.ajax = new AjaxFunctions();
    this.dialogs = new CoDialog();
  }

  init() {
    this.activate.onLoad();

    this.simpleSlider.init();
    this.sectionInView.init();
    this.sticky.init();
    this.scrollTo.init();
    this.toTop.init();
    this.dropdowns.init();
    this.formTips.init();
    this.cartCoupon.init();
    this.woocommerce.init();
    this.mobileMenu.init();
    this.formTip.init();
    this.magazineTilesCarousel.init();
    this.relatedPosts.init();
    this.share.init();
    this.toggle.init();
    this.productDetails.init();

    this.pageScroll.init();

    newsletterInit();

    this.ajax.init();
    this.dialogs.init();
  }
}

const app = new App();

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => {
    app.init();
  });
} else {
  app.init();
}
