export default class Share {
  constructor() {
    this.selectors = {
      share: '.js-share',
    };

    this.DOM = {
      share: document.querySelector(this.selectors.share),
    };
  }

  init() {
    if (!this.DOM.share) {
      return;
    }

    const position = this.DOM.share.getBoundingClientRect();

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const posTop = position.top + position.height + scrollTop;

    document.addEventListener('scroll', () => {
      const currentScrollTop =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop;

      this.DOM.share.classList.toggle('is-bottom', currentScrollTop > posTop);
    });
  }
}
