/* eslint-disable no-param-reassign */
export default class Activate {
  /**
   * One plugin to fit them all...
   * @param {Node} root Root node to search for activate elements in.
   * @example <caption>Simplest usage</caption>
   *   <button js-activate="#target">Trigger</button>
   *   <div id="target">Target</div>
   * @example <caption>Multiple targets</caption>
   *   <button js-activate=".klass-selector">Trigger</button>
   *   <div class="klass-selector">Target A</div>
   *   <div class="klass-selector">Target B</div>
   * @example <caption>Toggling</caption>
   *   <button js-activate="#target" js-activate-togglable>Trigger</button>
   *   <div id="target">Target to open and close</div>
   * @example <caption>Initially active togglable</caption>
   *   <button js-activate="#target" js-activate-togglable class="is-active">Trigger with .is-active class</button>
   *   <div id="target">Target</div>
   * @example <caption>One of set</caption>
   *   <a href="#target-a" js-activate="groupNameStartingWithLetter">Trigger A</a>
   *   <a href="#target-b" js-activate="groupNameStartingWithLetter">Trigger B</a>
   *   <div id="target-a" js-activate-target="groupNameStartingWithLetter">Target A</div>
   *   <div id="target-b" js-activate-target="groupNameStartingWithLetter">Target B</div>
   * @example <caption>Set target height</caption>
   *   <button js-activate="#target">Trigger</button>
   *   <div id="target" js-activate-set-height>Target</div>
   * @example <caption>Deactivate trigger</caption>
   *   <button js-activate="#target">Trigger</button>
   *   <div id="target">
   *     Target <button js-deactivate="#target">Close</button>
   *   </div>
   */

  constructor(root = document) {
    this.attributes = {
      trigger: 'js-activate',
      target: 'js-activate-target',
      deactivateTrigger: 'js-deactivate',
      togglable: 'js-activate-togglable',
      setHeight: 'js-activate-set-height',
      selector: 'js-activate-selector',
    };

    this.selectors = {
      anyTrigger: `[${this.attributes.trigger}]`,
      anyDeactivateTrigger: `[${this.attributes.deactivateTrigger}]`,
      trigger: name => `[${this.attributes.trigger}="${name}"]`,
      target: name => `[${this.attributes.target}="${name}"]`,
    };

    this.classes = {
      active: 'is-active',
    };

    this.isGroupRegExp = new RegExp('^[a-zA-Z]');

    this.onDeactivate = this.onDeactivate.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onEsc = this.onEsc.bind(this);

    this.root = root;
  }

  onLoad() {
    const triggers = Array.from(
      this.root.querySelectorAll(this.selectors.anyTrigger),
    );
    const knownGroups = [];

    triggers.forEach(trigger => {
      trigger.addEventListener('click', this.onClick);

      const groupName = trigger.getAttribute(this.attributes.trigger);
      if (knownGroups.indexOf(groupName) === -1) {
        knownGroups.push(groupName);

        if (
          this.isGroupRegExp.test(groupName) ||
          trigger.classList.contains(this.classes.active)
        ) {
          trigger.classList.remove(this.classes.active);
          trigger.click();
        }
      }
    });

    const deactivateTriggers = Array.from(
      this.root.querySelectorAll(this.selectors.anyDeactivateTrigger),
    );
    deactivateTriggers.forEach(trigger => {
      const groupName = trigger.getAttribute(this.attributes.deactivateTrigger);
      if (!this.isGroupRegExp.test(groupName)) {
        trigger.addEventListener('click', this.onDeactivate);
      }
    });

    if (window.location.hash) {
      const hashTrigger = triggers.filter(
        trigger => trigger.hash === window.location.hash,
      );

      if (hashTrigger.length) {
        hashTrigger[0].click();
      }
    }
  }

  onUnload() {
    const triggers = Array.from(
      this.root.querySelectorAll(this.selectors.anyTrigger),
    );
    triggers.forEach(trigger => {
      trigger.removeEventListener('click', this.onClick);
    });

    const deactivateTriggers = Array.from(
      this.root.querySelectorAll(this.selectors.anyDeactivateTrigger),
    );
    deactivateTriggers.forEach(trigger => {
      trigger.removeEventListener('click', this.onDeactivate);
    });
  }

  onClick(ev, forceDeactivate = false) {
    const trigger = ev.currentTarget;

    if (
      trigger.nodeName !== 'INPUT' ||
      ['button', 'submit', 'reset', 'image'].includes(
        trigger.getAttribute('type'),
      )
    ) {
      ev.preventDefault();
    }

    const groupName = trigger.getAttribute(this.attributes.trigger);

    let selector = groupName;
    if (trigger.hasAttribute(this.attributes.selector)) {
      selector = trigger.getAttribute(this.attributes.selector);
    } else if (trigger.hash) {
      selector = trigger.hash;
    }

    const targets = Array.from(document.querySelectorAll(selector));

    let activate = !forceDeactivate;
    if (trigger.hasAttribute(this.attributes.togglable)) {
      activate = !targets[0].classList.contains(this.classes.active);
    }

    if (activate) {
      if (this.isGroupRegExp.test(groupName)) {
        const relatedTriggers = Array.from(
          document.querySelectorAll(this.selectors.trigger(groupName)),
        ).filter(relatedTrigger => relatedTrigger !== trigger);
        const relatedTargets = Array.from(
          document.querySelectorAll(this.selectors.target(groupName)),
        ).filter(relatedTarget => targets.indexOf(relatedTarget) < 0);

        this.deactivate(relatedTriggers, relatedTargets);
      }

      trigger.classList.add(this.classes.active);
      targets.forEach(target => {
        target.classList.add(this.classes.active);
        target.dispatchEvent(new window.CustomEvent('activate:active'));

        const setHeight = target.hasAttribute(this.attributes.setHeight);
        if (setHeight) {
          window.requestAnimationFrame(() => {
            const targetHeight = target.clientHeight;
            if (setHeight) {
              target.style.height = 0;
            }
            window.requestAnimationFrame(() => {
              if (setHeight) {
                target.style.height = `${targetHeight}px`;
              }
            });
          });
        }
      });

      document.addEventListener('keydown', this.onEsc);
    } else {
      this.deactivate([trigger], targets);
    }
  }

  onDeactivate(ev) {
    ev.preventDefault();

    const trigger = ev.currentTarget;
    const groupName = trigger.getAttribute(this.attributes.deactivateTrigger);
    const targetSelector = trigger.hash ? trigger.hash : groupName;

    const targets = Array.from(document.querySelectorAll(targetSelector));
    const relatedTriggers = Array.from(
      document.querySelectorAll(this.selectors.trigger(targetSelector)),
    );

    relatedTriggers.forEach(relatedTrigger => {
      relatedTrigger.classList.remove(this.classes.active);
    });

    this.deactivate([trigger], targets);
  }

  deactivate(triggers, targets) {
    triggers.forEach(trigger => {
      trigger.classList.remove(this.classes.active);
    });

    targets
      .filter(target => target.classList.contains(this.classes.active))
      .forEach(target => {
        const setHeight = target.hasAttribute(this.attributes.setHeight);
        if (setHeight) {
          const onTransitionEnd = () => {
            target.classList.remove(this.classes.active);
            target.dispatchEvent(new window.CustomEvent('activate:inactive'));
            if (setHeight) {
              target.style.height = null;
            }

            target.removeEventListener('transitionend', onTransitionEnd);
          };
          target.addEventListener('transitionend', onTransitionEnd);
          if (setHeight) {
            target.style.height = 0;
          }
        } else {
          target.classList.remove(this.classes.active);
          target.dispatchEvent(new window.CustomEvent('activate:inactive'));
        }
      });

    document.removeEventListener('keydown', this.onEsc);
  }

  onEsc(ev) {
    if (ev.key !== 'Esc' && ev.key !== 'Escape') {
      return;
    }

    const allActive = Array.from(
      document.querySelectorAll(this.selectors.anyTrigger),
    )
      .filter(item => {
        let active = item.classList.contains(this.classes.active);
        if (active && item.hasAttribute(this.attributes.togglable)) {
          // active && togglable - deactivate immediately
          item.click();
          active = false;
        }
        return active;
      })
      .map(trigger => {
        // extract selector we will need to search for deactivate trigger
        let selector = trigger.getAttribute(this.attributes.trigger);
        if (trigger.hasAttribute(this.attributes.selector)) {
          selector = trigger.getAttribute(this.attributes.selector);
        } else if (trigger.hash) {
          selector = trigger.hash;
        }
        return selector;
      });

    // find matching deactivate triggers
    Array.from(document.querySelectorAll(this.selectors.anyDeactivateTrigger))
      .filter(
        trigger =>
          allActive.indexOf(
            trigger.getAttribute(this.attributes.deactivateTrigger),
          ) > -1,
      )
      .forEach(item => {
        item.click();
      });
  }
}
