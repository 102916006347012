export default class PageScroll {
  constructor() {
    this.classes = {
      transparent: 'has-transparent-header',
    };

    this.offset = 70;
  }

  init() {
    this.getDOM();
    this.bindScrollEvent();
  }

  bindScrollEvent() {
    if (!this.DOM.body) {
      return;
    }
    window.addEventListener('optimizedScroll', this.onScroll.bind(this));
  }

  onScroll() {
    const { scrollTop } = document.documentElement;
    if (scrollTop >= this.offset) {
      this.DOM.body.classList.remove(this.classes.transparent);
    } else {
      this.DOM.body.classList.add(this.classes.transparent);
    }
  }

  getDOM() {
    this.DOM = {
      body: document.querySelector(`.${this.classes.transparent}`),
    };
  }
}
