/* eslint-disable */
export default class RelatedPosts {
  constructor() {
    this.selectors = {
      desktop: '.js-related-posts',
      mobile: '.js-related-posts-mobile',
    };

    this.DOM = {
      desktop: document.querySelector(this.selectors.desktop),
      mobile: document.querySelector(this.selectors.mobile),
    };
  }

  bindResizeEvent() {
    const changeEvent = event => {
      if (event.matches) {
        if (this.DOM.desktop.innerHTML === '') {
          this.moveElement('desktop');
        }
      } else {
        this.moveElement('mobile');
      }
    };

    if (typeof this.breakpoint.addEventListener === 'undefined') {
      this.breakpoint.addListener(changeEvent);
    } else {
      this.breakpoint.addEventListener('change', changeEvent);
    }
  }

  init() {
    if (!this.DOM.desktop || !this.DOM.mobile) {
      return;
    }

    this.breakpoint = window.matchMedia('(min-width: 1024px)');
    this.bindResizeEvent();

    if (!this.breakpoint.matches) {
      this.moveElement('mobile');
    }
  }

  moveElement( destination ) {
    if (destination === 'mobile') {
      this.DOM.mobile.innerHTML = this.DOM.desktop.innerHTML;
      this.DOM.desktop.innerHTML = '';
    } else {
      this.DOM.desktop.innerHTML = this.DOM.mobile.innerHTML;
      this.DOM.mobile.innerHTML = '';
    }
  }
}
