export default class FormTips {
  constructor() {
    this.selectors = {
      inputs: 'input[data-tip]',
      label: id => `label[for=${id}]`,
    };

    this.templates = {
      icon: '<span class="c-form-tip__icon">i</span>',
      tip: text => `<span class="c-form-tip__contents">${text}</span>`,
    };

    this.classes = {
      label: 'c-form-tip',
    };
  }

  init() {
    this.insertTips(document);

    const observer = new MutationObserver(mutationList => {
      mutationList.forEach(mutation => {
        switch (mutation.type) {
          case 'childList':
            if (mutation.addedNodes.length > 0) {
              const nodes = Array.from(mutation.addedNodes);
              nodes.forEach(node => {
                if (node.nodeType === Node.ELEMENT_NODE) {
                  this.insertTips(node);
                }
              });
            }
            break;
          default:
            break;
        }
      });
    });

    Array.from(document.getElementsByTagName('form')).forEach(el => {
      observer.observe(el, { subtree: true, childList: true });
    });
  }

  insertTips(root) {
    const inputs = Array.from(root.querySelectorAll(this.selectors.inputs));

    inputs.forEach(input => {
      if (input.dataset.tipReady) {
        return;
      }

      let label = root.querySelector(this.selectors.label(input.id));
      if (!label) {
        label = input.closest('label');
        if (!label) {
          return;
        }
      }

      label.classList.add(this.classes.label);

      this.insertHTML(label, this.templates.icon);
      this.insertHTML(label, this.templates.tip(input.dataset.tip));

      // eslint-disable-next-line no-param-reassign
      input.dataset.tipReady = true;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  insertHTML(parent, htmlString) {
    const range = document.createRange();
    range.selectNode(parent);
    const documentFragment = range.createContextualFragment(htmlString);
    parent.appendChild(documentFragment);
  }
}
