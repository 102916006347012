import 'waypoints/lib/noframework.waypoints';
import 'waypoints/lib/shortcuts/inview';

export default class ToTop {
  constructor() {
    this.selectors = {
      header: '.c-header',
      target: '.c-footer__to-top',
    };

    this.classes = {
      active: 'is-active',
    };
  }

  init() {
    const header = document.querySelector(this.selectors.header);
    const link = document.querySelector(this.selectors.target);

    if (header && link) {
      // eslint-disable-next-line no-unused-vars, no-undef
      const inview = new Waypoint.Inview({
        element: header,
        enter: direction => {
          if (direction === 'up') {
            link.classList.remove(this.classes.active);
          }
        },
        exited: direction => {
          if (direction === 'down') {
            link.classList.add(this.classes.active);
          }
        },
      });
    }
  }
}
