import Choices from 'choices.js/public/assets/scripts/choices';

class Dropdown {
  constructor() {
    this.classes = {
      containerClass: 'choices o-input o-input__choices',
      selectContainerClass: 'choices o-input o-input__choices-dropdown',
    };
  }

  init(el, addTypeClass = false, onlySearch = false, asSelect = false) {
    const dropdown = this;
    const args = {
      editItems: false,
      shouldSort: false,
      classNames: {
        containerOuter: this.classes.containerClass,
      },
    };
    if (asSelect) {
      args.searchEnabled = false;
      args.placeholder = false;
      args.resetScrollPosition = false;
      args.classNames.containerOuter = this.classes.selectContainerClass;
    } else {
      args.choices = JSON.parse(el.dataset.choices);
      args.removeItemButton = true;
      args.searchResultLimit = 20;
      args.fuseOptions = {
        threshold: 0.0,
      };
    }
    if (addTypeClass) {
      args.callbackOnCreateTemplates = this.addTypeClass;
      args.searchFields = [
        'label',
        'value',
        'customProperties.parent',
        'customProperties.unaccentedParent',
        'customProperties.unaccented',
      ];
      args.searchResultLimit = 15;
      args.fuseOptions = {
        shouldSort: false,
        threshold: 0.0,
        tokenize: true,
        matchAllTokens: true,
      };
    }
    if (onlySearch) {
      args.callbackOnInit = function callbackOnInit() {
        const input = this.input.element;
        input.addEventListener('keyup', dropdown.onKeyUp.bind(dropdown));
      };
    }
    this.dropdown = new Choices(el, args);
    if (onlySearch) {
      this.showOnlySearchResults();
    }
    if ('items' in el.dataset) {
      const items = Array.from(JSON.parse(el.dataset.items));
      items.forEach(item => this.dropdown.setChoiceByValue(item));
    }
    this.dropdown.passedElement.element.addEventListener(
      'choice',
      this.onChoice.bind(this),
    );
  }

  showOnlySearchResults() {
    const el = this.dropdown.passedElement.element;
    el.addEventListener('search', this.onSearch.bind(this));
    el.addEventListener('showDropdown', this.onShowDropdown.bind(this));
  }

  addTypeClass(template) {
    return {
      choice: (classNames, data) =>
        template(
          `<div class="${data.customProperties.type} ${classNames.item} ` +
            `${classNames.itemChoice} ${
              data.disabled
                ? classNames.itemDisabled
                : classNames.itemSelectable
            }" data-select-text="${this.config.itemSelectText}" data-choice ${
              data.disabled
                ? 'data-choice-disabled aria-disabled="true"'
                : 'data-choice-selectable'
            } data-id="${data.id}" data-value="${data.value}" ${
              data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
            }>
            ${data.label}
          </div>`,
        ),
    };
  }

  onSearch() {
    this.dropdown.showDropdown();
  }

  onShowDropdown() {
    if (!this.dropdown.input.value.length) {
      this.dropdown.dropdown.hide();
    }
  }

  onKeyUp() {
    this.onShowDropdown();
  }

  onChoice() {
    this.dropdown.dropdown.hide();
  }
}

export default class Dropdowns {
  constructor() {
    this.attributes = {
      addType: 'addType',
      onlySearchResults: 'onlySearch',
      setterTarget: 'js-choices-value',
    };

    this.selectors = {
      input: '[js-choices]',
      dropdown: '[js-choices-dropdown]',
      valueSetter: `[${this.attributes.setterTarget}]`,
    };

    this.choices = {};
  }

  init() {
    this.initDropdowns();
    this.initInputs();
    this.initSetters();
  }

  initDropdowns() {
    const dropdowns = Array.from(
      document.querySelectorAll(this.selectors.dropdown),
    );

    dropdowns.forEach(el => {
      const dropdown = new Dropdown();
      dropdown.init(el, false, false, true);
      const name = el.getAttribute('name');
      if (name) {
        this.choices[name] = dropdown;
      }
    });
  }

  initInputs() {
    const inputs = Array.from(document.querySelectorAll(this.selectors.input));

    inputs.forEach(el => {
      let addType = false;
      let onlySearch = false;
      if (this.attributes.addType in el.dataset) {
        addType = true;
      }
      if (this.attributes.onlySearchResults in el.dataset) {
        onlySearch = true;
      }
      const dropdown = new Dropdown();
      dropdown.init(el, addType, onlySearch);
      const name = el.getAttribute('name');
      if (name) {
        this.choices[name] = dropdown;
      }
    });
  }

  initSetters() {
    const setters = Array.from(
      document.querySelectorAll(this.selectors.valueSetter),
    );

    setters.forEach(el => {
      el.addEventListener('click', this.onSetterClick.bind(this));
    });
  }

  onSetterClick(ev) {
    ev.preventDefault();
    const el = ev.currentTarget;
    const targetName = el.getAttribute(this.attributes.setterTarget);
    const targetValue = el.innerText;

    if (!(targetName in this.choices)) {
      return;
    }

    const targetDropdown = this.choices[targetName].dropdown;
    targetDropdown.setChoiceByValue(targetValue);
  }
}
