import qs from 'querystring';
import axios from '../vendor/axios';

class Newsletter {
  constructor(container) {
    this.container = container;
    this.selectors = {
      newsletterForm: '[js-newsletter-form]',
      newsletterStatus: '[js-newsletter-form-status]',
      close: '[js-newsletter-close]',
    };
    this.classes = {
      error: 'error',
      success: 'success',
      hidden: 'hidden',
      active: 'is-active',
    };
    this.ajaxAction = 'newsletter';
    this.init();
  }

  init() {
    this.form = this.container.querySelector(this.selectors.newsletterForm);

    this.statusContainer = this.container.querySelector(
      this.selectors.newsletterStatus,
    );

    if (this.form) {
      this.form.addEventListener('submit', this.handleSubmit);
    }

    this.closeBtn = this.container.querySelector(this.selectors.close);
    if (!this.closeBtn) {
      return;
    }

    this.closeBtn.addEventListener('click', this.onClose);

    window.addEventListener('hashchange', this.onHashChange);
    this.onHashChange();

    this.container.addEventListener('click', this.onClick);

    this.autoShowNewsletterPopup();
  }

  handleSubmit = ev => {
    ev.preventDefault();
    this.resetStatusContainer();
    this.sendRequest(window.globals.ajax_url);
  };

  onClose = ev => {
    ev.preventDefault();
    ev.stopPropagation();

    this.container.focus(); // I will focus myself to avoid matching focus-within
    this.container.classList.remove(this.classes.active);

    window.history.pushState(null, null, '.');

    // add some delay
    setTimeout(() => {
      this.container.addEventListener('click', this.onClick);
    }, 500);
  };

  onHashChange = () => {
    if (this.container.id === window.location.hash.substring(1)) {
      this.open();
    }
  };

  onClick = () => {
    if (!this.container.classList.contains(this.classes.active)) {
      this.open();
    }
  };

  open = () => {
    const today = new Date();
    this.container.classList.add(this.classes.active);
    this.container.removeEventListener('click', this.onClick);
    window.localStorage.setItem('newsletterLastSeen', today.toISOString());
  };

  autoShowNewsletterPopup() {
    const newsletterHash = this.container.getAttribute('js-newsletter');
    const newsletterLastSignup = window.localStorage.getItem(
      'newsletterLastSignup',
    );
    // if user already signed up, has the offer changed since that time?
    if (newsletterLastSignup !== newsletterHash) {
      const autoTimeout = parseInt(
        this.container.getAttribute('js-newsletter-auto'),
        10,
      );
      // should the popup appear on this page?
      if (autoTimeout) {
        const lastSeen = new Date(
          window.localStorage.getItem('newsletterLastSeen'),
        );
        const today = new Date();
        // have user not seen it in the last 24h?
        if (today - lastSeen > 24 * 60 * 60 * 1000) {
          setTimeout(() => {
            this.container.click();
          }, autoTimeout * 1000);
        }
      }
    }
  }

  sendRequest(url) {
    const payload = this.collectData();
    payload.action = this.ajaxAction;
    axios
      .post(url, qs.stringify(payload))
      .then(this.handleSuccess.bind(this))
      .catch(e => this.handleError(e.response));
  }

  resetStatusContainer() {
    this.statusContainer.classList.remove(
      this.classes.error,
      this.classes.success,
    );
    this.statusContainer.innerText = '';
  }

  handleSuccess(response) {
    if (response.data.success) {
      this.statusContainer.classList.add(this.classes.success);
      this.statusContainer.innerText = response.data.message;
      this.form.classList.add(this.classes.hidden);

      const newsletterHash = this.container.getAttribute('js-newsletter');
      window.localStorage.setItem('newsletterLastSignup', newsletterHash);
    } else {
      this.handleError(response);
    }
  }

  handleError(error) {
    this.statusContainer.classList.add(this.classes.error);
    this.statusContainer.innerText = error.data.message;
  }

  collectData() {
    const inputs = Array.from(this.form.querySelectorAll('input'));
    const data = {};
    inputs.forEach(input => {
      data[input.getAttribute('name')] = input.value;
    });

    return data;
  }
}

export default function initNewsletters() {
  const formContainers = Array.from(
    document.querySelectorAll('[js-newsletter]'),
  );
  formContainers.forEach(container => new Newsletter(container));
}
