const selectors = {
  tip: '.c-form-tip',
  icon: '.c-form-tip__icon',
  contents: '.c-form-tip__contents',
};

class Tip {
  constructor(container) {
    this.container = container;
    this.icon = this.container.querySelector(selectors.icon);
    this.contents = this.container.querySelector(selectors.contents);

    this.mouseOver = this.mouseOver.bind(this);

    this.init();
  }

  init() {
    this.icon.addEventListener('mouseover', this.mouseOver);
  }

  mouseOver() {
    const viewportWidth = window.innerWidth;
    const yShift =
      this.container.offsetTop > 0 && viewportWidth < 480
        ? this.container.offsetTop + 3
        : 0;
    this.contents.style = `transform: translate(0px, ${yShift}px)`;
    const boundingRect = this.contents.getBoundingClientRect();
    if (boundingRect.x < 0) {
      this.contents.style = `transform: translate(${-boundingRect.x}px, ${yShift}px)`;
    } else if (boundingRect.x + boundingRect.width > viewportWidth) {
      const translation = viewportWidth - boundingRect.x - boundingRect.width;
      this.contents.style = `transform: translate(${translation}px, ${yShift}px)`;
    }
  }
}

export default class FormTip {
  constructor() {
    this.tips = document.querySelectorAll(selectors.tip);
  }

  init() {
    Array.from(this.tips).forEach(tip => new Tip(tip));
  }
}
