import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

export default class ScrollTo {
  constructor() {
    this.attributes = {
      target: 'js-scroll-to',
    };

    this.selectors = {
      link: `[${this.attributes.target}]`,
    };
  }

  init() {
    const links = Array.from(document.querySelectorAll(this.selectors.link));

    links.forEach(link => {
      link.addEventListener('click', this.onClick.bind(this, link));
    });
  }

  onClick(link, ev) {
    ev.preventDefault();
    const target = document.querySelector(
      link.getAttribute(this.attributes.target),
    );

    const extraOffset = link.getAttribute('data-offset') || 0;

    const position = target.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const offset = position.top + scrollTop - extraOffset;

    window.scroll({
      top: offset,
      left: 0,
      behavior: 'smooth',
    });

    target.focus();
  }
}
