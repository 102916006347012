/* eslint-disable no-unused-vars, no-undef */
import 'waypoints/lib/noframework.waypoints';

export default class Sticky {
  constructor() {
    this.selectors = {
      container: `[js-sticky]`,
      element: `[js-sticky-element]`,
    };

    this.classes = {
      sticky: 'is-sticky',
      atBottom: 'is-at-bottom',
    };

    this.containers = null;
  }

  init() {
    this.containers = Array.from(
      document.querySelectorAll(this.selectors.container),
    );

    if (!this.containers.length) {
      return;
    }

    this.containers.forEach(container => {
      const element = container.querySelector(this.selectors.element);
      const top = new Waypoint({
        element: container,
        handler: direction => {
          if (direction === 'down') {
            element.style.width = `${element.clientWidth}px`;
            element.style.height = `${element.clientHeight}px`;
            element.classList.add(this.classes.sticky);
          } else {
            element.classList.remove(this.classes.sticky);
            element.style.width = '';
            element.style.height = '';
          }
        },
      });
      const bottom = new Waypoint({
        element: container,
        handler: direction => {
          if (direction === 'down') {
            element.classList.add(this.classes.atBottom);
          } else {
            element.classList.remove(this.classes.atBottom);
          }
        },
        offset: () => -container.clientHeight + element.clientHeight,
      });
    });
  }
}
