/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
export default class CartCoupon {
  constructor() {
    this.selectors = {
      container: '[js-cart-coupon]',
      placeholder: '[js-cart-coupon-placeholder]',
      form: 'form.checkout_coupon',
    };
  }

  init() {
    const container = document.querySelector(this.selectors.container);
    const placeholder = document.querySelector(this.selectors.placeholder);

    if (!container || !placeholder) {
      return;
    }

    this.placeCartCoupon(container, placeholder);

    window.addEventListener(
      'resize',
      this.onResize.bind(this, container, placeholder),
    );

    // handle form processing/errors
    const observer = new MutationObserver(mutationList => {
      mutationList.forEach(mutation => {
        if (mutation.type === 'attributes') {
          this.onResize(container, placeholder);
        }
      });
    });

    observer.observe(container.querySelector(this.selectors.form), {
      attributes: true,
      attributeFilter: ['class'],
    });
  }

  placeCartCoupon(container, placeholder) {
    const containerBCR = container.getBoundingClientRect();
    [container, placeholder].forEach(el => {
      el.style.width = `100%`;
      el.style.height = `${containerBCR.height}px`;
      el.style.pointerEvents = 'none';
    });
    window.requestAnimationFrame(() => {
      const newContainerBCR = container.getBoundingClientRect();
      const placeholderBCR = placeholder.getBoundingClientRect();
      container.style.transform = `translateY(${placeholderBCR.top -
        newContainerBCR.top}px)`;
      container.style.position = 'absolute';
    });
  }

  resetCartCoupon(container) {
    container.style.width = '';
    container.style.height = '';
    container.style.transform = '';
    container.style.position = '';
  }

  onResize(container, placeholder) {
    this.resetCartCoupon(container);
    this.placeCartCoupon(container, placeholder);
  }
}
