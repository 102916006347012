const $ = window.jQuery;
const ajaxUrl = window.globals.ajax_url;

export default class AjaxFunctions {
  constructor() {
    this.selectors = {
      confirm_email: '.co_email_confirm',
      form_row: '.form-row',
    };
    this.css_classes = {
      invalid: 'co-invalid-field',
    };
  }

  init() {
    this.confirmEmail();
  }

  confirmEmail() {
    const selector = this.selectors.confirm_email;
    const invalidRequired = this.css_classes.invalid;
    const parentClass = this.selectors.form_row;
    $('body').on('blur', selector, e => {
      const o = $(e.currentTarget);
      const val = o.val();
      if (val && val.length > 5) {
        $.post(
          ajaxUrl,
          {
            email: val,
            action: 'co_email_exists',
          },
          data => {
            if (data.exists) {
              o.closest(parentClass).addClass(invalidRequired);
              if (!$('#co_email_in_use').length) {
                $('body').append(
                  '<dialog class="co-dialog co" id="co_email_in_use"><div class="woocommerce-info">Email already in use.</div><div class="content"><p>An account already exists for <span id="co_email_in_use_email"></span>. Please log in or use a different email address.</p></div><div class="buttons"><button id="co_email_in_use_login" class="o-btn">Log In</button><button id="co_email_in_use_cancel" class="o-btn">Change Email</button></div></dialog>',
                );
              }
              $('#co_email_in_use_email').text(val);
              document.getElementById('co_email_in_use').showModal();
            } else {
              o.closest(parentClass).removeClass(invalidRequired);
              if ($('#co_email_in_use').length) {
                document.getElementById('co_email_in_use').close();
              }
            }
          },
          'json',
        );
      }
    });
  }
}
