class SingleSimpleSlider {
  constructor(container, options) {
    this.options = options;

    this.autoInterval = options.auto;
    this.visible = options.visible;

    this.classes = {
      active: 'is-active',
      past: 'is-past',
      future: 'is-future',
      enabled: 'is-enabled',
      disableTransition: 'is-transition-disabled',
      cloneNext: 'js-clone-next',
      clonePrev: 'js-clone-prev',
    };

    this.selectors = options.selectors;

    this.container = container;
    this.list = this.container.querySelector(this.selectors.list);

    if (this.selectors.item) {
      this.items = Array.from(
        this.container.querySelectorAll(this.selectors.item),
      );
    } else {
      this.items = Array.from(this.list.children);
    }

    if (this.items.length < 2) {
      // nothing to do
      this.container.dispatchEvent(new CustomEvent('noSlides'));
      return;
    }

    // wait until all images are loaded
    Promise.all(
      this.items.map(
        item =>
          new Promise(resolve => {
            const img = item.querySelector('img');
            if (img.complete && img.naturalWidth !== 0) {
              resolve(true);
              return;
            }

            const tmpImg = new Image();
            tmpImg.addEventListener('load', () => resolve(true));
            tmpImg.addEventListener('error', () => resolve(false));
            tmpImg.src =
              img.src || img.dataset.src || img.dataset.cookieblockSrc;
          }),
      ),
    ).then(() => {
      // how many times to clone 'next' items?
      const lastItemDim = this.items[
        this.items.length - 1
      ].getBoundingClientRect();
      const space = document.documentElement.clientWidth - lastItemDim.right;
      const times = Math.max(1, Math.ceil(space / lastItemDim.width));
      // multiplicate items
      this.itemsPrev = this.items.map(item => {
        const clone = this.prepareClone(item, this.classes.clonePrev);
        this.list.insertBefore(clone, this.items[0]);
        return clone;
      });
      this.itemsNext = this.items.map(item => {
        const clone = this.prepareClone(item, this.classes.cloneNext);
        return this.list.appendChild(clone);
      });
      if (times > 1) {
        let additionalItems = [];
        for (let i = 1; i < times; i += 1) {
          additionalItems = additionalItems.concat(
            this.itemsNext.map(item => {
              const clone = item.cloneNode(true);
              return this.list.appendChild(clone);
            }),
          );
        }
        this.itemsNext = this.itemsNext.concat(additionalItems);
      }
      this.allItems = [].concat(this.itemsPrev, this.items, this.itemsNext);

      this.container.classList.add(this.classes.enabled);

      this.container.addEventListener('goTo', this.goTo);
      this.container.addEventListener('next', this.next);
      this.container.addEventListener('prev', this.prev);
      this.container.addEventListener('pause', this.pause);
      this.container.addEventListener('resume', this.resume);

      window.addEventListener('resize', this.resize, {
        passive: true,
      });

      // go to first slide
      this.container.dispatchEvent(
        new CustomEvent('goTo', {
          detail: {
            index: 0,
          },
        }),
      );

      // run slider
      this.resume();

      this.container.dispatchEvent(
        new CustomEvent('ready', {
          detail: {
            simpleSlider: this,
          },
        }),
      );

      // are we on touch-capable device? because:
      // https://codeburst.io/the-only-way-to-detect-touch-with-javascript-7791a3346685 ;)
      window.addEventListener(
        'touchstart',
        this.onFirstTouch,
        {
          passive: true,
        },
        false,
      );
    });
  }

  // eslint-disable-next-line class-methods-use-this
  prepareClone(item, className) {
    const clone = item.cloneNode(true);
    clone.removeAttribute('id');
    clone.setAttribute('aria-hidden', 'true');
    if (clone.tagName === 'A') {
      clone.setAttribute('tabindex', '-1');
    } else {
      const focusable = Array.from(
        clone.querySelectorAll('a, button, input, select'),
      );
      focusable.forEach(f => {
        f.setAttribute('tabindex', '-1');
      });
    }
    clone.classList.add(className);
    return clone;
  }

  // private

  onFirstTouch = () => {
    // setup touch handling
    this.list.addEventListener(
      'touchstart',
      this.onTouchStart,
      {
        passive: true,
      },
      false,
    );
    this.list.addEventListener(
      'touchend',
      this.onTouchEnd,
      {
        passive: true,
      },
      false,
    );

    window.removeEventListener('touchstart', this.onFirstTouch, false);
  };

  onTouchStart = ev => {
    [this.lastTouchStart] = ev.changedTouches;
    this.pause();
  };

  onTouchEnd = ev => {
    if (this.lastTouchStart) {
      const [touchEnd] = ev.changedTouches;
      let { minSwipeLength } = this.options;
      minSwipeLength = minSwipeLength || 10;

      if (touchEnd.screenX < this.lastTouchStart.screenX - minSwipeLength) {
        this.next();
      } else if (
        touchEnd.screenX >
        this.lastTouchStart.screenX + minSwipeLength
      ) {
        this.prev();
      }

      this.resume();
      this.lastTouchStart = null;
    }
  };

  rewind(offset, callback) {
    this.container.classList.add(this.classes.disableTransition);

    requestAnimationFrame(() => {
      if (offset > 0) {
        this.setTransformOnList(
          -this.itemsPrev[this.itemsPrev.length - 1].offsetLeft,
        );
        this.setItemsClasses(this.itemsPrev);
      } else {
        this.setTransformOnList(-this.itemsNext[0].offsetLeft);
        this.setItemsClasses(this.itemsNext);
      }

      requestAnimationFrame(() => {
        this.container.classList.remove(this.classes.disableTransition);

        requestAnimationFrame(callback);
      });
    });
  }

  setTransformOnList(value) {
    this.list.style.transform = `translate3d(${value}px, 0, 0)`;
  }

  setItemsClasses(items) {
    this.allItems.forEach(item => {
      item.classList.remove(this.classes.active);
      item.classList.remove(this.classes.past);
      item.classList.remove(this.classes.future);
    });

    const currentAllIndex = this.allItems.indexOf(items[this.currentIndex]);
    this.allItems
      .slice(currentAllIndex, currentAllIndex + this.visible)
      .forEach(item => item.classList.add(this.classes.active));
    if (currentAllIndex > 0) {
      this.allItems[currentAllIndex - 1].classList.add(this.classes.past);
    }
    if (currentAllIndex + this.visible < this.allItems.length) {
      this.allItems[currentAllIndex + this.visible].classList.add(
        this.classes.future,
      );
    }
  }

  // public

  goTo = ev => {
    let { index, offset } = ev.detail;

    if (this.visible === undefined || this.visible === 'auto') {
      this.visible = Math.floor(
        this.container.clientWidth / (this.items[0].clientWidth - 1),
      );
    }

    if (offset === undefined) {
      offset = 0;
    }

    if (index === undefined) {
      const firstItem = this.allItems.filter(item =>
        item.classList.contains(this.classes.active),
      );
      if (firstItem.length) {
        index =
          this.allItems.indexOf(firstItem[0]) + offset - this.itemsPrev.length;
      } else {
        index = 0;
      }
    }

    const maxIndex = this.items.length - 1;
    this.currentIndex = (index + maxIndex + 1) % (maxIndex + 1);

    this.setItemsClasses(this.items);

    if (
      (this.currentIndex === 0 && offset > 0) ||
      (this.currentIndex === maxIndex && offset < 0)
    ) {
      this.rewind(offset, () => {
        this.setTransformOnList(-this.items[this.currentIndex].offsetLeft);
      });
    } else {
      this.setTransformOnList(-this.items[this.currentIndex].offsetLeft);
    }
  };

  next = () => {
    this.goTo({
      detail: {
        offset: 1,
      },
    });
  };

  prev = () => {
    this.goTo({
      detail: {
        offset: -1,
      },
    });
  };

  pause = () => {
    clearTimeout(this.autoTimeout);
  };

  resume = () => {
    if (this.autoInterval && this.visible < this.items.length) {
      this.autoTimeout = setTimeout(() => {
        requestAnimationFrame(() => {
          this.goTo({
            detail: {
              offset: 1,
            },
          });
          this.resume();
        });
      }, this.autoInterval);
    }
  };

  resize = () => {
    this.visible = this.options.visible;

    this.goTo({
      detail: {
        offset: 0,
      },
    });

    this.pause();
    this.resume();
  };
}

export default class SimpleSlider {
  constructor() {
    this.selectors = {
      slider: '[js-simple-slider]',
      list: '[js-simple-slider-list]',
      item: null,
      prev: '[js-simple-slider-prev]',
      next: '[js-simple-slider-next]',
    };

    this.sliders = [];

    this.onNoSlides = this.onNoSlides.bind(this);
    this.onReady = this.onReady.bind(this);
  }

  init(root = document) {
    Array.from(root.querySelectorAll(this.selectors.slider)).forEach(slider => {
      slider.addEventListener('noSlides', this.onNoSlides);
      slider.addEventListener('ready', this.onReady);

      // eslint-disable-next-line no-unused-vars
      const singleSlider = new SingleSimpleSlider(slider, {
        auto: 0,
        selectors: this.selectors,
      });
    });
  }

  onNoSlides(ev) {
    const slider = ev.target;
    const prev = slider.querySelector(this.selectors.prev);
    const next = slider.querySelector(this.selectors.next);

    if (prev) {
      prev.style.display = 'none';
    }
    if (next) {
      next.style.display = 'none';
    }
  }

  onReady(ev) {
    const slider = ev.target;
    const { simpleSlider } = ev.detail;

    this.sliders.push(simpleSlider);

    const prev = slider.querySelector(this.selectors.prev);
    if (prev) {
      prev.addEventListener('click', () => {
        simpleSlider.prev();
      });
    }

    const next = slider.querySelector(this.selectors.next);
    if (next) {
      next.addEventListener('click', () => {
        simpleSlider.next();
      });
    }
  }
}
